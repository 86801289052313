import { useState, useEffect } from 'react';

export const useIsDesktop = () => {
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsDesktop(document.body.getAttribute('data-device-type') === 'desktop');
		}
	}, []);

	return isDesktop;
};
