import { createStore, createEvent } from 'effector';

export enum PillType {
	Radio = 'radio',
	Checkbox = 'checkbox',
	Anchor = 'anchor',
	Button = 'button',
}

export type THorizontalPills = {
	title: string;
	pillType: PillType;
	horizontalGroup: string;
	pills: Array<TPill>;
	onSelect?: (pill: TPill) => void;
	onClick?: (pill: TPill) => void;
	__component: 'web-components.horizontal-generic-links';
	isSearchPage?: boolean;
};

export type TPill = {
	label: string;
	href: string;
	target: string;
	leftImg?: string;
	rightImg?: string;
	isActive?: boolean;
};

export const toggleRadio = createEvent<{ group: string; label: string }>();
export const toggleCheckbox = createEvent<{ group: string; label: string }>();

export const $selectedRadioStore = createStore<{ [key: string]: string | null }>({}).on(
	toggleRadio,
	(state, { group, label }) => {
		const newState = { ...state };
		newState[group] = label;
		return newState;
	},
);

export const $selectedCheckboxStore = createStore<{ [key: string]: Set<string> }>({}).on(
	toggleCheckbox,
	(state, { group, label }) => {
		const groupState = state[group] || new Set();
		const newState = new Set(groupState);
		if (newState.has(label)) {
			newState.delete(label);
		} else {
			newState.add(label);
		}
		return { ...state, [group]: newState };
	},
);
