import { LocalizedLink } from '@/components/Link';
import classNames from 'classnames';
import styles from './styles.module.css';
import Image from 'next/image';
import { getStandardCardHoverBlockType } from '@/features/carousel/model';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import React, { useContext } from 'react';
import { AnalyticsContext } from '@/components/Layout';
import { TSTStateCalltoaction } from '@/uikit/element_atoms/call-to-action';
import { renderMarkup } from '@/uikit/components/ark-ui-atom-button-calltoaction/_index';
import { UiKitRenderStatic } from '@/components/UiKit/UiKitRender';
import { useScreenSize } from '@/shared/utils/useScreensize';
import { EScreenSize } from '@/shared/utils/screensize';
import { hexToRgb } from '../../uikit/helper_funcs/hexToRgb';

export const StandardCard = (props: {
	id: number;
	slug: string;
	href: string;
	background: string;
	hoverBlock?: {
		type?: 'partial' | 'full' | 'none' | null;
		title?: string;
		description?: string;
		cta?: string;
		overrides?:
			| {
					color?: string | null;
					background?: string | null;
					cta?: {
						color?: string | null;
						background?: string | null;
						background_end?: string | null;
						border?: string | null;
					} | null;
			  }
			| any;
	} | null;
	loading: 'eager' | 'lazy' | undefined;
	style?: any;
	badge?: { label: string; backgroundColor: string; labelColor: string } | null;
}) => {
	const overrideBg = props?.hoverBlock?.overrides?.background;
	const style = cleanObj({
		'--hover-block-color': props?.hoverBlock?.overrides?.color,
		'--hover-block-background': overrideBg ? `${hexToRgb(overrideBg)}, 0.7)` : 'rgba(0, 0, 0, 0.7)',
	});

	// Replace the parsing logic with a simple function to strip HTML tags
	const stripHtml = (html: string) => {
		return html.replace(/<[^>]*>/g, '');
	};

	const description = stripHtml(props?.hoverBlock?.description ?? '');

	function cleanObj(obj: any): { [key: string]: string } {
		Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
		return obj;
	}
	const { isMobile } = useDeviceDetector();
	const screenSize = useScreenSize();
	const isCTAButtonVisible = screenSize !== EScreenSize.sm;
	const isMobileDetected = isMobile();
	const hoverBlockType = getStandardCardHoverBlockType(isMobileDetected, props?.hoverBlock?.type);

	const { AITracks } = useContext(AnalyticsContext);
	const clickHandler = (ev: any) => {
		ev?.stopPropagation?.();
		AITracks?.genericTrack?.({
			eventName: 'gameCard',
			isNonInteraction: false,
			eventAction: AnalyticsEventAction.CLICK,
			customDimensions: {
				cardType: 'StandardCard',
				cardTitle: props?.hoverBlock?.title,
				cardImageURL: props?.background,
				badge_text: props?.badge?.label,
			},
		});
	};

	const labelTitle = props?.hoverBlock?.title ? `Play ${props?.hoverBlock?.title}` : 'Clickable card';
	const alttitle = props?.hoverBlock?.title ?? 'Card Image';
	const isGradientButton =
		props?.hoverBlock?.overrides?.buttonBackground_end &&
		props?.hoverBlock?.overrides?.buttonBackground_end !== props?.hoverBlock?.overrides?.buttonBackground;
	const btnStateBasic: TSTStateCalltoaction = {
		'size': 'small',
		'text': props?.hoverBlock?.cta || 'Play now',
		'icon-url': '/assets/play.svg',
		'icon-position': 'before',
		'tabIndex': -1,
		'override_button_normal_color': props?.hoverBlock?.overrides?.buttonTextColor,
		'override_button_normal_background': props?.hoverBlock?.overrides?.buttonBackground,
		'override_button_normal_background_end': props?.hoverBlock?.overrides?.buttonBackground_end,
		'override_button_normal_border': props?.hoverBlock?.overrides?.buttonBorderColor,
		'override_button_normal_should_use_border': props?.hoverBlock?.overrides?.should_button_use_border !== false,
		'override_button_hover_background': props?.hoverBlock?.overrides?.buttonBackground,
		'override_button_hover_background_end': props?.hoverBlock?.overrides?.buttonBackground_end,
		'override_button_active_background': props?.hoverBlock?.overrides?.buttonBackground,
		'override_button_active_background_end': props?.hoverBlock?.overrides?.buttonBackground,
		...(!isGradientButton
			? {}
			: {
					override_button_hover_should_use_border: true,
					override_button_hover_border: props?.hoverBlock?.overrides?.buttonBorderColor,
					override_button_active_should_use_border: true,
					override_button_active_border: props?.hoverBlock?.overrides?.buttonBorderColor,
				}),
		'override_is_hover_block': true,
	};
	const btnStateThemeDark: TSTStateCalltoaction = {
		...btnStateBasic,
		styling: 'outline',
		addClass: `__dark ${styles.__catPageBtn}`,
		addToId: '__dark',
	};

	//extra <div / is needed to separate the badge from the background and not be affected by the overflow:hidden
	return (
		<LocalizedLink
			style={props?.style}
			className={styles.link}
			href={props.href}
			onClick={clickHandler}
			target={'_self'}
			aria-label={labelTitle}
			prefetch='default'
		>
			<span
				className='ark-ui-atom-badge card_badge'
				aria-label='card badge'
				style={{
					backgroundColor: props?.badge?.backgroundColor,
					color: props?.badge?.labelColor,
				}}
			>
				{props.badge?.label}
			</span>
			<div className={styles.cardWrapper}>
				<Image
					alt={alttitle}
					className={styles.background}
					src={props.background}
					loading={props.loading}
					width={288}
					height={192}
				/>
				<div
					className={classNames(styles['hover-block'], {
						[styles.partial]: hoverBlockType === 'partial',
						[styles.full]: hoverBlockType === 'full',
					})}
					style={style}
				>
					<p className={styles.title}>{props?.hoverBlock?.title}</p>
					<p className={styles.description}>{description}</p>
					{hoverBlockType === 'full' && isCTAButtonVisible && (
						<UiKitRenderStatic
							tagName={'ark-ui-atom-button-calltoaction'}
							innerHTML={renderMarkup(btnStateThemeDark)}
							onClick={clickHandler}
						/>
					)}
				</div>
			</div>
		</LocalizedLink>
	);
};
