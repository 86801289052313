import React from 'react';

import { TPill } from './model';
import { LocalizedLink } from '@/components/Link';
import { StaticImage } from '@/components/StaticImage';
import classNames from 'classnames';
import styles from './styles.module.css';

export const Pill = (props: TPill) => {
	const { pillType, prefix, label, selected, onClick, onChange, href, target, leftImg, rightImg } = props;
	const id = `pill-title-${prefix}-${label}`;
	function getCustomStyles() {
		return {
			...(props?.override_border_color && { '--border-color': props.override_border_color }),
			...(props?.override_label_color && { '--label-color': props.override_label_color }),
			...(props?.override_background_color && { '--background-color': props.override_background_color }),
			...(props?.override_border_color_hover && { '--border-color-hover': props.override_border_color_hover }),
			...(props?.override_label_color_hover && { '--label-color-hover': props.override_label_color_hover }),
			...(props?.override_background_color_hover && {
				'--background-color-hover': props.override_background_color_hover,
			}),
			...(props?.override_border_color_focus && { '--border-color-focus': props.override_border_color_focus }),
			...(props?.override_label_color_focus && { '--label-color-focus': props.override_label_color_focus }),
			...(props?.override_background_color_focus && {
				'--background-color-focus': props.override_background_color_focus,
			}),
			...(props?.override_border_color_active && { '--border-color-active': props.override_border_color_active }),
			...(props?.override_label_color_active && { '--label-color-active': props.override_label_color_active }),
			...(props?.override_background_color_active && {
				'--background-color-active': props.override_background_color_active,
			}),
			'--left-padding': props?.leftImg ? '3rem' : '1rem',
			'--right-padding': props?.rightImg ? '3rem' : '1rem',
		};
	}

	// Function to create the input element based on pillType
	const createInputElement = () => {
		switch (pillType) {
			case 'radio':
				return (
					<input id={id} name={prefix} type='radio' checked={selected} value={label} onChange={onChange} />
				);
			case 'checkbox':
				return (
					<input id={id} name={prefix} type='checkbox' checked={selected} value={label} onChange={onChange} />
				);
			case 'button':
				return (
					<button id={id} type='button' onClick={onClick}>
						{label}
					</button>
				);
			case 'anchor':
				return (
					<LocalizedLink
						id={id}
						href={href ? href : '#'}
						target={target}
						className={classNames({ active: selected })}
					>
						{label}
					</LocalizedLink>
				);
			default:
				return (
					<input id={id} name={prefix} type='radio' checked={selected} value={label} onChange={onChange} />
				);
		}
	};

	// Generate the input element
	const inputElement = createInputElement();

	const customStyles: any = getCustomStyles();
	return (
		<>
			<style>{`
            .ark-ui-pill {
                border: none;
                ${Object.keys(customStyles)
					.map((prop) => prop + ':' + customStyles[prop] + ';')
					.join('')}
                }
        `}</style>
			<label
				key={id}
				className={classNames('ark-ui-pill', 'text-16', styles.Pill, {
					'__no-use-borders_normal': props.override_use_border === false,
					'__no-use-borders_hover': props.override_use_border_hover === false,
					'__no-use-borders_active': props.override_use_border_active === false,
				})}
				htmlFor={id}
				data-label={label}
				aria-label={label}
				style={
					{
						'--left-padding': customStyles['--left-padding'],
						'--right-padding': customStyles['--right-padding'],
					} as any
				}
			>
				{leftImg && (
					<StaticImage width={24} height={24} loading='lazy' className='left-img' src={leftImg} alt={label} />
				)}
				{inputElement}
				{rightImg && (
					<StaticImage
						width={24}
						height={24}
						loading='lazy'
						className='right-img'
						src={rightImg}
						alt={label}
					/>
				)}
			</label>
		</>
	);
};
